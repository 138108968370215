'use strict'

ROTATE_DEG = 0.02
# ROTATE_DEG = 2
COLORS = [
	'#f5f5f5'
	'#e2f4ff'
	'#f4fede'
	'#fff7dd'
	'#ffebef'
]

yagawa = {}

class Positioner
	_: null
	x: 0
	y: 0
	changed: false
	constructor: (snap, roller) ->
		@_ = snap.g roller
	translate: (x, y) ->
		@x = x
		@y = y
		@changed = true
		return
	update: ->
		if @changed
			m = new Snap.matrix()
			m.translate @x, @y
			@_.attr
				transform: m
			@changed = false
		return

class Roller
	_: null
	r: 0
	changed: false
	constructor: (snap, obj) ->
		@_ = snap.g obj
	rotate: (r) ->
		@r = r
		@changed = true
		return
	rotateAdd: (r) ->
		@rotate @r + r
	update: ->
		if @changed
			m = new Snap.matrix()
			m.rotate @r
			@_.attr
				transform: m
			@changed = false
		return

class RollerBG
	_: null
	r: 0
	changed: false
	constructor: ($el) ->
		@_ = $el
	rotate: (r) ->
		@r = r
		@changed = true
		return
	rotateAdd: (r) ->
		@rotate @r + r
	update: ->
		if @changed
			value = "rotate(#{@r}deg)"
			@_.css
				transform: value
			@changed = false
		return

class SVGElem
	_: null
	_attr: null
	changed: false
	constructor: (obj) ->
		@_ = obj
	attr: (attr = @_attr) ->
		if attr
			@_attr = attr
			@changed = true
		return
	update: ->
		if @changed
			@_.attr @_attr
			@changed = false
		return

$ ->
	##
	# ホバー
	#
	##
	$('a[href]').bcWink()

	##
	# トップスライドショー
	#
	##
	$slideshow = $('.page-home .main-image')
	$mainImage = $('.main-image img').eq(0)
	if $slideshow.length
		do ->
			$slideshow.psycle
				transition: 'fade'
				delay: 8000
				duration: 1200
				repeat: 'return'
				auto: false

			liLength = $slideshow.find('li').length

			p = $slideshow.data 'psycle'

			$marker = p.marker()
			$marker.addClass 'main-image-marker'
			$marker.find('li').text (i, text) -> "0#{i}"
			$marker.appendTo $('.main');

			p.on 'init', (e) ->
				setTimeout ->
					p.next()
					p.play()
				, 3000
				return

			p.on 'panelChangeStart', (e) ->
				changeColor COLORS[e.data.to]
				return
			p.on 'panelChangeEnd', (e) ->
				return

			ID = '_js_bg'

			$svg = $ "<svg id=\"#{ID}\" />"
			$svg.appendTo $ 'body'

			css =
				width: '100%'
				height: '100%'
				position: 'absolute'
				zIndex: 0
				top: 0
				left: 0

			$svg.css css

			s = Snap "##{ID}"

			rect = new SVGElem s.rect fill: COLORS[0]
			roller = new Roller s, rect._
			positioner = new Positioner s, roller._

			props =
				width: 0
				height: 0
				x: 0
				y: 0
				fill: COLORS[0]

			$bg = $('<div class="main-image-bg"></div>')
			$base = $slideshow.find('ul')
			$bg.css
				width: $base.width()
				height: $base.height()
				position: 'absolute'
				top: 60
				left: $base.offset().left
				transformOrigin: 'center center'
			$bg.appendTo $slideshow
			bg = new RollerBG $bg

			changeColor = (color) ->
				props.fill = color
				rect.attr props
				return

			update = ->
				rect.update()
				roller.update()
				positioner.update()
				bg.update()
				$svg.css css
				return

			updater = new baser.AnimationFrames update
			updater.start()

			roll = ->
				roller.rotateAdd ROTATE_DEG
				bg.rotateAdd ROTATE_DEG
				return

			rollTimer = new baser.Timer
			rollTimer.on 'progress', roll
			rollTimer.start()

			resize = ->
				# get
				w = document.documentElement.clientWidth
				h = document.documentElement.scrollHeight
				# rect
				size = Math.max w * 2, h * 2
				props.width = size
				props.height = size
				props.y = size * -.5
				rect.attr props
				# position
				# .main-imageの画像の中心
				offset = $mainImage.offset()
				x = offset.left + ($mainImage.width() / 2)
				y = offset.top + ($mainImage.height() / 2)
				positioner.translate x, y
				bg._.css
					top: 60
					left: $base.offset().left
					width: $base.width()
					height: $base.height()
				$svg.attr 'class', ''
				if document.documentElement.clientHeight < h
					css.height = h
				else
					css.height = '100%'
				return
			baser.Browser.browser.on 'resizeend', resize

			yagawa.resize = resize
			return

	##
	# スマホグロナビ
	#
	##
	$('[data-toggle-for]').each ->
		$toggle = $ @
		id = $toggle.data 'toggleFor'
		if id
			try
				$target = $ "##{id}"
				$toggle.on 'click', ->
					$target.stop(on, on).slideToggle 500, ->
						yagawa.resize()
						return
					return false
			catch err
				# err

	##
	# 高さ揃え
	#
	##
	alignOption =
		defaults: 3
		768: 2
	$('.content-index--box .item__thumb').bcBoxAlignHeight alignOption
	$('.content-index--box .item__title').bcBoxAlignHeight alignOption
	$('.content-index--box .item__meta').bcBoxAlignHeight alignOption
	$('.content-index--box .item__desc').bcBoxAlignHeight alignOption

	##
	# 背景画像化
	#
	##
	$('.item__thumb').bcBackground()
